<template>
    <v-container fluid>
        <template>
            <div style="background:white;width:100%;margin:0%;padding:0%;">
                <v-row>
                    <v-col cols="12">
                        <v-row>
                            <v-col cols="6" style="padding-left: 2%;padding-right: 2%;">
                                <div class="row mt-4" >
                                    <div class="col">
                                        <h2 class="text-center">RENDIMIENTO DE VENTAS DIARIAS</h2>
                                        <v-row>
                                            <v-col cols="4" style="text-align:left;" v-if="totalVentas.total_hoy">
                                                <span style="font-size:12px">Hoy</span>
                                                <h4>S/ {{totalVentas.total_hoy}}</h4>
                                            </v-col>
                                            <v-col cols="4" style="text-align:left;" v-else>
                                                <span style="font-size:12px">Hoy</span>
                                                <h4>S/ 0.00</h4>
                                            </v-col>
                                            <v-col cols="4" style="text-align:left;">
                                                <span style="font-size:12px">Ayer</span>
                                                <h4>S/ {{totalVentas.total_ayer}}</h4>
                                            </v-col>
                                            <v-col cols="4" style="text-align:left;">
                                                <span style="font-size:12px">el último Mes</span>
                                                <h4>S/ {{totalVentas.total_mes}}</h4>
                                            </v-col>
                                        </v-row>
                                        <v-tabs-items v-model="tab">
                                            <v-tab-item
                                                v-for="(item, indicetab) in itemDias"
                                                :key="indicetab"
                                            >
                                                <line-Chart v-if="item.tab == 'Hoy'"
                                                    :chartData="arrVentasHoy"
                                                    :options="chartOptionsProductos"
                                                    :chartColors="productosColor"
                                                    :height="200"
                                                />
                                                
                                                <line-chart v-else
                                                    :chartData="arrVentasAyer"
                                                    :options="chartOptionsProductos"
                                                    :chartColors="productosColor"
                                                    :height="200"
                                                />
                                            </v-tab-item>
                                        </v-tabs-items>
                                        <v-tabs
                                            v-model="tab"
                                            style="background-color: transparent!important;"
                                        >
                                            <v-tab
                                                v-for="(item,indicetab) in itemDias"
                                                :key="indicetab"
                                                style="width:100%;background: rgb(182 182 182);color: black;margin:1%;border: 1px solid #717171;border-radius: 3px;"
                                                elevation="2"
                                            >
                                                {{ item.tab }}
                                            </v-tab>
                                        </v-tabs>
                                    </div>
                                </div>
                                <div class="row mt-4" >
                                    <div class="col">
                                        <h2 class="text-center">RENDIMIENTO DE VENTAS DEL MES</h2>
                                        <v-row>
                                            <v-col cols="4" style="text-align:left;">
                                                <span style="font-size:12px">Este Mes</span>
                                                <h4>S/ {{totalVentasMes.total_mes}}</h4>
                                            </v-col>
                                            <v-col cols="4" style="text-align:left;">
                                                <span style="font-size:12px">El Mes pasado</span>
                                                <h4>S/ {{totalVentasMes.total_mes_pasado}}</h4>
                                            </v-col>
                                            <v-col cols="4" style="text-align:left;">
                                                <span style="font-size:12px">el último Año</span>
                                                <h4>S/ {{totalVentasMes.total_anio}}</h4>
                                            </v-col>
                                        </v-row>
                                        <v-tabs-items v-model="tabMes">
                                            <v-tab-item
                                                v-for="(item, indice) in itemMes"
                                                :key="indice"
                                            >
                                                <line-Chart v-if="item.tabMes == 'Este mes'"
                                                    :chartData="arrVentasMes"
                                                    :options="chartOptionsProductos"
                                                    :chartColors="productosColor"
                                                    :height="200"
                                                />
                                                
                                                <line-chart v-else
                                                    :chartData="arrVentasMesPasado"
                                                    :options="chartOptionsProductos"
                                                    :chartColors="productosColor"
                                                    :height="200"
                                                />
                                            </v-tab-item>
                                        </v-tabs-items>
                                        <v-tabs
                                            v-model="tabMes"
                                            style="background-color: transparent!important;"
                                        >
                                            <v-tab
                                                v-for="(item,imes) in itemMes"
                                                :key="imes"
                                                style="width:100%;background: rgb(182 182 182);color: black;margin:1%;border: 1px solid #717171;border-radius: 3px;"
                                                elevation="2"
                                            >
                                                {{ item.tabMes }}
                                            </v-tab>
                                        </v-tabs>
                                    </div>
                                </div>
                            </v-col>
                            <v-col cols="6" style="padding-left: 2%;padding-right: 2%;">
                                <div class="row mt-4" >
                                    <div class="col">
                                        <h2 class="text-center">DESEMPEÑO DE VENTAS ANUAL</h2>
                                        <v-row style="text-align:center;">
                                            <v-col cols="3">

                                            </v-col>
                                            <v-col cols="6" class="text-center">
                                                <v-progress-circular
                                                    :rotate="90"
                                                    :size="230"
                                                    :width="30"
                                                    :value="val"
                                                    color="red"
                                                    style="color: #708dbd!important"
                                                >
                                                    <strong>{{val}}%</strong>
                                                </v-progress-circular>
                                            </v-col>
                                            <v-col cols="3">
                                                    <h2>S/{{total}}</h2>
                                                    <h2>de</h2>
                                                    <h2>S/1 000 000</h2>
                                            </v-col>
                                        </v-row>
                                        <v-row v-for="item in meses" :key="item.mes">
                                            <v-col cols="2" style="text-align: end;">
                                                <strong>{{item.mes}}</strong>
                                            </v-col>
                                            <v-col cols="10">
                                                <v-progress-linear
                                                    :value="item.valor"
                                                    striped
                                                    color="#26beba"
                                                    height="25"
                                                >
                                                    <strong>{{item.valor}}%</strong>
                                                </v-progress-linear>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </div>
                            </v-col>
                        
                        </v-row>
                    </v-col>
                </v-row>
            </div>
        </template>
    </v-container>
</template>

<script>
import {mapState, mapActions} from 'vuex';
import LineChart from "../../../components/graficos-ventas/LineChart";

export default {
    name: 'DashboardVentas',
    components: {
        LineChart
    },
    data () {
        return {
            mesNombre : ['ENERO','FEBRERO','MARZO','ABRIL','MAYO','JUNIO','JULIO','AGOSTO','SETIEMBRE','OCTUBRE','NOVIEMBRE','DICIEMBRE'],
            meses : [],
            val: 0,
            tab: null,
            tabMes: null,
            itemDias: [
                { tab: 'Ayer', content: 'Tab 1 Content' },
                { tab: 'Hoy', content: 'Tab 2 Content' },
            ],
            itemMes: [
                { tabMes: 'El mes pasado', content: 'Tab 1 Content' },
                { tabMes: 'Este mes', content: 'Tab 2 Content' },
            ],
            arrVentasHoy: [],
            arrVentasAyer: [],
            arrVentasMes: [],
            arrVentasMesPasado: [],
            productosColor: {
                borderColor: "#251F47",
                pointBorderColor: "#260F26",
                pointBackgroundColor: "#858EAB",
                backgroundColor: [
                    "#35599D","#A3446F","#3098B6","#33939B","#4C3C9E","#7F429F"
                ],
            },
            chartOptionsProductos:{
                
            },
            chartOptionsLineChart:{
                
            },
            total: 0
        }
    },
    computed: {
        ...mapState('dashboard', [
            'ventasHoy',
            'ventasAyer',
            'ventasMes',
            'ventasMesPasado',
            'totalVentas',
            'totalVentasMes',
            'rendimientoVentas'
        ])
    },
    methods: {
        ...mapActions('dashboard', [
            'getVentasHoy',
            'getVentasAyer',
            'getVentasMes',
            'getVentasMesPasado',
            'getTotalVentas',
            'gettotalVentasMes'
        ]),
    },
    created() {
        console.log(this.rendimientoVentas);
        this.total = this.rendimientoVentas[0].sum;
        this.val = ((this.total/1000000)*100).toFixed(2);
        
        let arrData = this.rendimientoVentas;
        console.log(arrData.length)
        console.log(this.meses)
        let cont = 0;
        for (let i = 0; i < arrData.length; i++) {
            console.log(arrData[i].mes)
            if (i>0) {
                let data = null;
                if (arrData[i].mes != null) {
                    data = {
                        'mes' : this.mesNombre[i-1],
                        'valor' : ((arrData[i].mes/this.total)*100).toFixed(2)
                    }
                } else {
                    data = {
                        'mes' : this.mesNombre[i-1],
                        'valor' : 0.00
                    }
                }
                cont = parseFloat(parseFloat(cont) + parseFloat(data['valor']))
                this.meses.push(data);
            }
        }
        console.log(this.meses)
        console.log(cont)

        const dataHoy = this.ventasHoy;
        const dataAyer = this.ventasAyer;
        const dataMes = this.ventasMes;
        const dataMesPasado = this.ventasMesPasado;
        
        dataHoy.forEach(d => {
            const date = d.producto;
            const {
                fechaventa,
                cantidad,
                sale_id,
            } = d;

            this.arrVentasHoy.push({total: cantidad, abre:fechaventa.substring(0,5),});
        });
        dataAyer.forEach(d => {
            const date = d.producto;
            const {
                fechaventa,
                cantidad,
                sale_id,
            } = d;

            this.arrVentasAyer.push({total: cantidad, abre:fechaventa.substring(0,5),});
        });
        dataMes.forEach(d => {
            const date = d.producto;
            const {
                fechaventa,
                total,
                sale_id,
            } = d;

            this.arrVentasMes.push({total: total, abre:fechaventa.substring(5,10),});
        });
        dataMesPasado.forEach(d => {
            const date = d.producto;
            const {
                fechaventa,
                total,
                sale_id,
            } = d;

            this.arrVentasMesPasado.push({total: total, abre:fechaventa.substring(5,10),});
        });
        console.log(this.arrVentasMesPasado);
    },
}
</script>

<style scoped>

</style>